const NotFound = () => {

    console.log("NotFound");
  
    return (
      <div>
        <h1 className="hcolor">NotFound</h1>
      </div>
    );
  };
  
  export default NotFound;
  