import { BrowserRouter } from "react-router-dom";
import Header from "./Header";
import Navigation from "./Navigation";
import Footer from "./Footer";
import CreateRoutes from "../routes/routes";
// basename="/reactweb"

const Website = () => {
  return (
    <div>
      <BrowserRouter>
        <Header />
        <Navigation />
        <CreateRoutes />
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default Website;
