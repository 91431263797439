const Products = () => {

  console.log("Products");

  return (
    <div>
      <h1 className="hcolor">Products</h1>
    </div>
  );
};

export default Products;
