import { Link } from "react-router-dom";

const Navigation = () => {
  return (
    <div>
      <h2>Navigation </h2>
      <nav>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/services">Services</Link></li>
          <li><Link to="/products">Products</Link></li>
        </ul>
      </nav>
    </div>
  );
};

export default Navigation;
