const Services = () => {

  console.log("Services");

  return (
    <div>
      <h1 className="hcolor">Services</h1>
    </div>
  );
};

export default Services;
