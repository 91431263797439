import { Switch, Route } from "react-router-dom";
import Home from "../container/Home";
import Services from "../container/Services";
import Products from "../container/Products";
import NotFound from "../container/NotFound";

const CreateRoutes  = () => {
  // const { path } = useRouteMatch();
  // console.log(path);
  return (

    <Switch>
        {/* <Route path={path} exact><Home /></Route>
        <Route path={`${path}/services`} exact><Services/></Route>
        <Route path={`${path}/products`} exact><Products/></Route> */}

        <Route path="/" exact><Home /></Route>
        <Route path="/services" exact><Services /></Route>
        <Route path="/products" exact><Products /></Route>
        <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default CreateRoutes ;
