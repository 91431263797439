import "./App.css";
import Website from "./layout/Website";
import "./static/css/style.css";

function App() {
  return (
    <div className="App">
      <Website />
    </div>
  );
}

export default App;
